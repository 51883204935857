<template>
  <div
    class="mb-3"
  >
    <label
      v-if="label"
      class="form-label"
    >
      {{ `${label}&nbsp;` || '' }}
      <i
        v-if="description"
        ref="tooltip-toggler"
        :title="description"
        class="bi bi-info-circle"
        data-bs-toggle="tooltip"
      />
    </label>
    <div
      class="btn-group w-100 h-fit-content"
      :class="{ 'is-invalid': hasErrors }"
    >
      <textarea
        v-model="value"
        :class="{ 'is-invalid': hasErrors }"
        :readonly="readonly"
        :rows="numRows"
        class="form-control"
        @blur="e => $emit('blur', e)"
      />
    </div>
    <div class="invalid-feedback">
      <p
        v-for="error in errors"
        :key="error.$uid"
      >
        {{ error.$message }}
      </p>
    </div>
  </div>
</template>
<script>
import { Tooltip } from 'bootstrap';

export default {
  props: [
    'modelValue',
    'label',
    'description',
    'readonly',
    'errors',
    'inputType',
    'disableAutocomplete',
    'horizontal',
    'rows'
  ],
  emits: [ 'update:modelValue', 'blur' ],
  computed: {
    hasErrors() {
      return this.errors?.length > 0;
    },
    value: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      }
    },
    numRows() {
      return this.rows || 4;
    }
  },
  mounted() {
    if (this.description) {
      new Tooltip(this.$refs['tooltip-toggler']);
    }
  }
};
</script>
<style scoped>
.h-fit-content {
  height: fit-content;
}
</style>
