import _ from 'lodash';
import { millisecondsInDay } from '@/lib/date';
import { costSubcategoryToRevenueMapping } from '@/components/LaborPlanning/constants';

export const buildWeekPlan = (commitment, upcomingWeekDays) => {
  const revTypeToSubcategory = _.invert(costSubcategoryToRevenueMapping);
  const weekPlan = {};
  _.forEach(commitment, (value) => {
    _.forEach(upcomingWeekDays, (weekDay) => {
      if (!weekPlan[weekDay]) {
        weekPlan[weekDay] = {};
      }
      const subcategory = revTypeToSubcategory[value.revenueType];
      weekPlan[weekDay][subcategory] = value[weekDay];
    });
  });
  return weekPlan;
};

export const buildCommitment = (weekPlan, upcomingWeekDays, revenueTypes) => {
  const revTypeToSubcategory = _.invert(costSubcategoryToRevenueMapping);
  const commitment = [];
  _.forEach(revenueTypes, (revenueType) => {
    const subcategory = revTypeToSubcategory[revenueType];
    const row = { revenueType };
    _.forEach(upcomingWeekDays, (date) => {
      weekPlan === undefined ? row[date] = 0 : row[date] = weekPlan[date][subcategory];
    });
    commitment.push(row);
  });
  return commitment;
};

export const calculateCombination = (
  dataset1,
  dataset2,
  cellEvaluator,
  rowKey = 'revenueType'
) => {
  const result = [];
  const data1 = _.keyBy(dataset1, rowKey);
  const data2 = _.keyBy(dataset2, rowKey);
  const rowNames = _.keys(data1);
  const colNames = _.filter(_.keys(dataset1[0]), (k) => k !== rowKey);

  _.forEach(rowNames, (rowName) => {
    const value = {};
    value[rowKey] = rowName;
    _.forEach(colNames, (colName) => {
      const val1 = _.get(data1[rowName], colName);
      const val2 = _.get(data2[rowName], colName);
      value[colName] = cellEvaluator(val1, val2);
    });
    result.push(value);
  });
  return result;
};

export const getCellEvaluator = (fractionDigits, costMultiplier) => {
  return (forecast, uph) => {
    if (forecast === 0 || !uph) {
      return (0).toFixed(fractionDigits);
    }
    return (forecast / uph * costMultiplier).toFixed(fractionDigits);
  };
};

export const uphCommitmentCellValidator = (value) => {
  const errors = [];
  if (!Number.isFinite(value) || !Number.isInteger(value)) {
    errors.push('The input value should be an integer number.');
  }

  if (value < 0) {
    errors.push('The input value should be positive.');
  }

  return errors;
};

export const planEditPeriodExpired = (plan) => {
  const todayStart = new Date().setUTCHours(0,0,0,0);
  const planStart = new Date(plan.startDate);
  if (plan.resubmissionAllowed) {
    return (planStart - todayStart)/millisecondsInDay <= 2;
  }

  return (planStart - todayStart)/millisecondsInDay <= 4;
};

export const planReviewPeriodExpired = (plan) => {
  // for the moment roles for the review are tha same as for the edit; it can be changed in future
  return planEditPeriodExpired(plan);
};

export const toUSMoneyFormat = (number) => {
  return new Intl.NumberFormat(
    'en-US',
    { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }
  ).format(number);
};
