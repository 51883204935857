<template>
  <div class="labor-planning-section card fit-content">
    <div class="m-3">
      <div class="d-flex justify-content-between pt-3 pl-3 pr-3 pb-0">
        <h5>
          <slot name="title" />
        </h5>
        <slot name="btn" />
      </div>
      <div
        class="card-body pt-0 pl-3 pr-3 pb-3"
        :style="{ height: `${tableHeight}px`}"
      >
        <AgGridVue
          :readOnlyEdit="editable"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="displayableData"
          :rowHeight="actualRowHeight"
          :headerHeight="headerRowHeight"
          :rowClassRules="rowClassRules"
          class="ag-theme-alpine h-100 w-100"
          @cell-edit-request="onCellEditRequest"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { AgGridVue } from 'ag-grid-vue3';
import _ from 'lodash';
import { revenueTypesList } from '@/components/LaborPlanning/constants';

const rowHeight = 42;
const headerRowHeight = 40;

export default {
  components: {
    AgGridVue
  },
  props: [ 'rowData', 'columnDefs', 'editable', 'showTotals', 'totalsFractionDigits', 'rowHeight', 'revenueTypesList' ],
  emits: ['cellEdited'],
  computed: {
    headerRowHeight() {
      return headerRowHeight;
    },
    defaultColDef() {
      return {
        resizable: true,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        sortable: false
      };
    },
    rowClassRules() {
      return {
        'totals-row': (params) => { return params?.data?.isTotalRow; },
        'bold-row': (params) => { return params?.data?.isBoldRow; }
      };
    },
    actualRowHeight() {
      return this.rowHeight || rowHeight;
    },
    tableHeight() {
      const additionalHeight = 20;
      return this.displayableData.length * this.actualRowHeight
        + this.headerRowHeight * 2 + additionalHeight;
    },
    displayableData() {
      const result = [...this.rowData];
      if (this.showTotals) {
        result.push({ ...this.totals, isTotalRow: true });
      }
      return result;
    },
    totals() {
      const keysForCalculation = _.filter(_.keys(this.rowData[0]), (k) => k !== 'revenueType');
      return this.calculateTotals(this.rowData, keysForCalculation, this.revenueTypes);
    },
    revenueTypes() {
      return this.revenueTypesList || revenueTypesList;
    }
  },
  methods: {
    calculateTotals(data, cols, rows) {
      const totalCategoryName = 'Total';
      const result = { revenueType: totalCategoryName };

      _.forEach(cols, (col) => result[col] = 0);
      _.forEach(data, (value) => {
        if (!rows.includes(value.revenueType)) {
          return;
        }
        _.forEach(cols, (col) => result[col] += parseFloat(value[col]));
      }, result);
      _.forEach(cols, (col) => {
        result[col] = result[col].toFixed(this.totalsFractionDigits || 0);
      });

      return result;
    },
    onCellEditRequest(event) {
      if (!this.editable) {
        return;
      }
      this.$emit('cellEdited', event);
    }
  }
};
</script>
<style>

.labor-planning-section {
  margin-top: 10px;
  border-top: none;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.labor-planning-section h5 {
  font-family: HW-Cigars-Regular, serif;
  font-size: 30px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0;
  text-align: left;
}

.labor-planning-section .ag-theme-alpine {
  --ag-border-color: #ffffff;
  --ag-header-background-color: #ffffff;
  --ag-font-family: Roobert-Regular, serif;
  --ag-odd-row-background-color: #f2f5f6;
  --ag-font-size: 15px;
}

/* header row bottom border */
.labor-planning-section .ag-theme-alpine .ag-header-row:last-child {
  border-bottom: 1px solid #b4aeae;
}

/* header rows cells styles */

.labor-planning-section .ag-theme-alpine .ag-header .ag-header-cell-text,
.labor-planning-section .ag-theme-alpine .ag-header .ag-header-group-text {
  font-size: 16px;
  font-weight: 700;
  line-height: 17.6px;
  text-align: center;
  text-transform: uppercase;
}

.labor-planning-section .ag-theme-alpine .ag-cell {
  font-weight: 400;
  text-align: center;
}

/* cell borders */
.labor-planning-section .ag-theme-alpine .ag-cell:first-child,
.labor-planning-section .ag-theme-alpine .ag-header-cell:first-child {
  border-right: 1px solid rgba(190, 233, 241, 1)
}

.labor-planning-section .ag-theme-alpine .ag-cell:last-child,
.labor-planning-section .ag-theme-alpine .ag-header-cell:last-child,
.labor-planning-section .ag-theme-alpine .ag-cell:nth-child(3),
.labor-planning-section .ag-theme-alpine .ag-header-cell:nth-child(3)
{
  border-right: 1px solid rgba(85, 185, 255, 1);
}

.labor-planning-section .ag-theme-alpine  .ag-row:last-child {
  border-bottom: 1px solid rgba(85, 185, 255, 1);
}

/* table header group colors */
.labor-planning-section .ag-theme-alpine .ag-header-group-cell:nth-child(1) {
  background-color: #ffffff !important;
}

.labor-planning-section .ag-theme-alpine .ag-header-group-cell:nth-child(2) {
  background-color: #BEE9F1 !important;
}

.labor-planning-section .ag-theme-alpine .ag-header-group-cell:nth-child(3) {
  background-color: #55B9FF !important;
  color: white;
}

/* editable cells styles */
.labor-planning-section .ag-input-wrapper.ag-text-field-input-wrapper {
  flex: none;
  width: 68px;
  height: 24px;
}

.labor-planning-section .ag-input-wrapper.ag-text-field-input-wrapper input {
  text-align: center;
}

.labor-planning-section .ag-cell-editor.ag-text-field.ag-input-field {
  justify-content: center;
  align-items: center;
}

/* custom styles for the Totals row and rows which should be bold */
.labor-planning-section .ag-theme-alpine .totals-row .ag-cell {
  font-size: 16px;
  font-weight: 700!important;
  color: rgba(85, 185, 255, 1);
}

.labor-planning-section .ag-theme-alpine .bold-row .ag-cell {
  font-size: 16px;
  font-weight: 700!important;
}
</style>
