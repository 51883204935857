<template>
  <span
    v-if="!isObject"
    class="d-block me-5 pe-1"
  >
    {{ colValue }}
  </span>
  <div
    v-else
  >
    <span
      class="d-block me-5 pe-1 lh-base forecast-data__container"
      :class="textAlignClass"
    >
      {{ colValue.forecastAvg }}
    </span>
    <span
      class="d-block me-5 pe-1 lh-base actual-data__container"
      :class="textAlignClass"
    >
      {{ colValue.actualAvg }}
    </span>
  </div>
</template>
<script>
export default {
  computed: {
    colValue() {
      const { field } = this.params.colDef;
      const rowData = this.params.data;
      return rowData[field];
    },
    isObject() {
      return typeof this.colValue === 'object';
    },
    textAlignClass() {
      return this?.params?.textAlign || 'right';
    }
  }
};
</script>
<style scoped>
.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.forecast-data__container {
  margin-top: 2px;
  font-size: 16px !important;
  line-height: 18px !important;
}
.actual-data__container {
  color: #918b8b;
  font-size: 14px !important;
  line-height: 15px !important;
}
</style>
