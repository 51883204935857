import { booleanCellRenderer, dayCellRenderer, workingDayCellRenderer } from '@/lib/agGridCellRenderers';

export const defaultValues = {
  id: null,
  brand: null,
  holidayProcessingMaxSeconds: 345600,
  holidayExceptionEnabled: true,
  holidayShiftExceptionEnabled: true,
  peakSeasonProcessorEnabled: true,
  peakSeasonUseCalendarDays: false,
  ffProcessingMaxSeconds: null,
  ffReceivedMaxTime: null,
  workingDays: [ 1, 2, 3, 4, 5 ],
  ffThreshold: 99,
  ffSentMaxTime: null,
  osrrReceivedMaxTime: null,
  nmsrProcessingMaxSeconds: null,
  nmsrThreshold: null,
  nmsrSentMaxTime: null,
  urProcessingMaxSeconds: null,
  urThreshold: null,
  urSentMaxTime: null,
  ffWholesaleProcessingMaxSeconds: 216000,
  ffWholesaleReceivedMaxTime: '11:59:59',
  fulfilmentWholesaleProcessingDays: 2,
  ltmProcessingDays: 4,
  ltmReceivedMaxTime: '11:59:59',
  ltmSentMaxTime: '23:59:59',
  postHoldTime: '07:00:00'
};

export const secsInDay = 3600 * 24;

// calculates t2 - t1 in seconds
// arguments format hh:mm:ss
// i.e. 00:00:10
export const timeDiff = (t1, t2) => {
  const t1Pieces = t1.split(':').map((i) => parseInt(i, 10));
  const t2Pieces = t2.split(':').map((i) => parseInt(i, 10));
  return (t2Pieces[0] - t1Pieces[0]) * 3600 + (t2Pieces[1] - t1Pieces[1]) * 60 + t2Pieces[2] - t1Pieces[2];
};

export const validateTimeEntry = (value) => {
  if (!value) {
    return true;
  }
  const regexp = /[0-2][0-9]:[0-5][0-9]:[0-5][0-9]/g;
  return regexp.test(value);
};

export const weekDayMapping = {
  1: 'Mo',
  2: 'Tu',
  3: 'We',
  4: 'Th',
  5: 'Fr',
  6: 'Sa',
  7: 'Su'
};

export const slaGreedColumns = ({ rowClickHandler }) => [
  {
    headerName: '',
    field: 'id',
    cellRenderer: 'AgGreedButtonCellRenderer',
    cellRendererParams: {
      clicked: (field) => {
        rowClickHandler(field);
      }
    },
    width: 80
  },
  {
    headerName: 'Id',
    field: 'id',
    width: 75
  },
  {
    headerName: 'Brand',
    field: 'brand',
    width: 75
  },
  {
    headerName: 'Holiday Exception',
    field: 'holidayExceptionEnabled',
    cellRenderer: booleanCellRenderer(),
    width: 100,
    wrapHeaderText: true,
    wrapText: true
  },
  {
    headerName: 'Holiday Shift Exception',
    field: 'holidayShiftExceptionEnabled',
    cellRenderer: booleanCellRenderer(),
    width: 100
  },
  {
    headerName: 'Peak Season Processor Enabled',
    field: 'peakSeasonProcessorEnabled',
    cellRenderer: booleanCellRenderer(),
    width: 100
  },
  {
    headerName: 'Peak Season Calculation Days',
    field: 'peakSeasonUseCalendarDays',
    cellRenderer: booleanCellRenderer({
      enabled: {
        label: 'Calendar',
        class: 'bg-warning'
      },
      disabled: {
        label: 'Business',
        class: 'bg-info'
      }
    }),
    width: 100
  },
  {
    headerName: 'Peak Season and Holidays Processing Max (Days)',
    field: 'holidayProcessingMaxSeconds',
    cellRenderer: dayCellRenderer,
    width: 95
  },
  {
    headerName: 'Ff Processing Max (Days)',
    field: 'ffProcessingMaxSeconds',
    cellRenderer: dayCellRenderer,
    width: 75
  },
  {
    headerName: 'Ff Received Max Time',
    field: 'ffReceivedMaxTime',
    width: 95
  },
  {
    headerName: 'Working Days',
    field: 'workingDays',
    cellRenderer: workingDayCellRenderer,
    width: 250
  },
  {
    headerName: 'Post Hold Time',
    field: 'postHoldTime',
    width: 100
  },
  {
    headerName: 'Ff Threshold',
    field: 'ffThreshold',
    width: 105
  },
  {
    headerName: 'Ff Sent Max Time',
    field: 'ffSentMaxTime',
    width: 100
  },
  {
    headerName: 'Osrr Received Max Time',
    field: 'osrrReceivedMaxTime',
    width: 100
  },
  {
    headerName: 'Nmsr Proc Max (Days)',
    field: 'nmsrProcessingMaxSeconds',
    cellRenderer: dayCellRenderer,
    width: 80
  },
  {
    headerName: 'Nmsr Threshold',
    field: 'nmsrThreshold',
    width: 100
  },
  {
    headerName: 'Nmsr Sent Max Time',
    field: 'nmsrSentMaxTime',
    width: 100
  },
  {
    headerName: 'Ur Proc Max (Days)',
    field: 'urProcessingMaxSeconds',
    cellRenderer: dayCellRenderer,
    width: 80
  },
  {
    headerName: 'Ur Threshold',
    field: 'urThreshold',
    width: 105
  },
  {
    headerName: 'Ur Sent Max Time',
    field: 'urSentMaxTime',
    width: 100
  },
  {
    headerName: 'Ff Wholesale Processing Max (Days)',
    field: 'ffWholesaleProcessingMaxSeconds',
    cellRenderer: dayCellRenderer,
    width: 75
  },
  {
    headerName: 'Ff Wholesale Received Max Time',
    field: 'ffWholesaleReceivedMaxTime',
    width: 95
  },
  {
    headerName: 'LTM Sent Max Time',
    field: 'ltmSentMaxTime',
    width: 95
  },
  {
    headerName: 'LTM Received Max Time',
    field: 'ltmReceivedMaxTime',
    width: 95
  },
  {
    headerName: 'LTM Processing Max (Days)',
    field: 'ltmProcessingMaxSeconds',
    cellRenderer: dayCellRenderer,
    width: 75
  },
];
