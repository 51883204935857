import { secsInDay, weekDayMapping } from '@/components/SlaConfiguration/constants';

export const booleanCellRenderer = (labelsConfig) => {
  const enabledLabel = labelsConfig?.enabled?.label || 'enabled';
  const disabledLabel = labelsConfig?.disabled?.label || 'disabled';

  const enabledClass = labelsConfig?.enabled?.class || 'bg-success';
  const disabledClass = labelsConfig?.disabled?.class || 'bg-danger';

  return (params) => {
    return params.value ?
      `<span class="badge ${enabledClass}">${enabledLabel}</span>` : `<span class="badge ${disabledClass}">${disabledLabel}</span>`;
  };
};

export const booleanIconCellRenderer = (labelsConfig) => {
  const enabledClass = labelsConfig?.enabled?.class || 'bi-check-circle text-success ';
  const disabledClass = labelsConfig?.disabled?.class || 'bi-x-circle text-warning';

  return (params) => {
    return params.value ?
      `<i class="bi ${enabledClass}" />` : `<i class="bi ${disabledClass}" />`;
  };
};


export const dayCellRenderer = (params) => {
  return Math.round(params.value / secsInDay * 100) / 100;
};

export const editableCellRenderer = (params) => {
  return `
    <div class="d-flex w-100 h-100 lh-base align-items-center justify-content-center">
      <div class="d-block overflow-hidden" style="width: 68px; height: 24px; text-overflow: ellipsis; border: 1px solid rgba(0, 0, 0, 1); border-radius: 4px;" >
        ${params.value}
      </div>
    </div>
`;
};

export const workingDayCellRenderer = (params) => {
  if (!params?.value || params?.value.length === 0) {
    return;
  }

  params.value.sort();
  return params.value.reduce(
    (acc, value) => {
      acc += `<span class="badge bg-info me-1">${weekDayMapping[value]}</span>`;
      return acc;
    },
    ''
  );
};

