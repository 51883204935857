<template>
  <div
    class="mb-3"
    :class="{ 'd-flex': multiple, 'flex-column': multiple }"
  >
    <label
      v-if="label"
      class="form-label"
    >
      {{ label || '' }}
      <i
        v-if="description"
        ref="tooltip-toggler"
        :title="description"
        class="bi bi-info-circle"
        data-bs-toggle="tooltip"
      />
    </label>
    <div
      class="btn-group w-100"
      :class="{ 'h-100': multiple }"
    >
      <slot name="before" />
      <select
        v-model="value"
        :class="computedClasses"
        :disabled="disabled"
        class="form-select"
        :multiple="multiple"
        @blur="e => $emit('blur', e)"
      >
        <option
          v-for="(option, index) in inputOptions"
          :key="index"
          :value="option.key"
          :selected="option.key === value ? 'selected' : false"
        >
          {{ option.value }}
        </option>
      </select>
      <slot name="after" />
    </div>
    <div class="invalid-feedback">
      <p
        v-for="error in errors"
        :key="error.$uid"
      >
        {{ error.$message }}
      </p>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import { Tooltip } from 'bootstrap';

export default {
  props: [
    'modelValue',
    'label',
    'description',
    'errors',
    'options',
    'disabled',
    'multiple',
    'showAddAll',
    'showRemoveAll',
    'selectClass'
  ],
  emits: [ 'update:modelValue', 'blur' ],
  data() {
    return {
      prefix: _.uniqueId()
    };
  },
  computed: {
    computedClasses() {
      const result = this.selectClass ? this.selectClass.split(' ') : [];
      if (this.hasErrors) {
        result.push('is-invalid');
      }

      return result;
    },
    hasErrors() {
      return this.errors?.length > 0;
    },
    addAllOption() {
      return { key: `${this.prefix}_ADD_ALL`, value: 'Select all' };
    },
    removeAllOption() {
      return { key: `${this.prefix}_REMOVE_ALL`, value: 'Remove all' };
    },
    inputOptions() {
      let opts = [...this.options];

      if (this.multiple && this.showRemoveAll) {
        opts = [ this.removeAllOption, ...opts ];
      }

      if (this.multiple && this.showAddAll) {
        opts = [ this.addAllOption, ...opts ];
      }

      return opts;
    },
    value: {
      get() {
        if (this.multiple && this.isAddAllSelected(this.modelValue)) {
          return _.map(this.options, 'key');
        }

        if (this.multiple && this.isRemoveAllSelected(this.modelValue)) {
          return [];
        }

        return this.modelValue;
      },
      set(val) {
        let value = val;

        if (this.multiple && this.isAddAllSelected(value)) {
          value = _.map(this.options, 'key');
        }

        if (this.multiple && this.isRemoveAllSelected(value)) {
          value = [];
        }

        this.$emit('update:modelValue', value);
      }
    }
  },
  mounted() {
    if (this.description) {
      new Tooltip(this.$refs['tooltip-toggler']);
    }
  },
  methods: {
    isAddAllSelected(modelValue) {
      return modelValue && modelValue.includes(this.addAllOption.key);
    },
    isRemoveAllSelected(modelValue) {
      return modelValue && modelValue.includes(this.removeAllOption.key);
    }
  }
};
</script>
