<template>
  <div
    ref="tooltip-toggler"
    :title="title"
    class="tooltip-holder"
    data-bs-toggle="tooltip"
    data-bs-custom-class="labor-plan-tooltip"
    data-html="true"
  >
    <i
      v-if="title.length"
      class="bi bi-info-circle"
    />
    <slot />
  </div>
</template>
<script>
import _ from 'lodash';
import { Tooltip } from 'bootstrap';
import { planStatuses } from '@/components/LaborPlanning/constants';

export default {
  props: ['laborPlan'],
  data() {
    return {
      tooltip: null
    };
  },
  computed: {
    title() {
      if (_.isEmpty(this.laborPlan)) {
        return '';
      }

      const plan = this.laborPlan;
      let tooltipHtml = '';

      if (plan?.reviewedBy?.email) {
        tooltipHtml += this.buildTipRow('Reviewed by:', plan?.reviewedBy?.email);
      }

      if (plan?.reviewedAt) {
        tooltipHtml += this.buildTipRow('Reviewed at:', plan?.reviewedAt);
      }

      if (plan?.status === planStatuses.RESUBMITTED && plan?.rejectReason) {
        tooltipHtml += this.buildTipRow('This plan is resubmitted. See previous reject reason below.', '');
      }

      if (plan?.rejectReason) {
        tooltipHtml += this.buildTipRow('Reject reason:', plan?.rejectReason);
      }

      return tooltipHtml;
    }
  },
  watch: {
    laborPlan() {
      this.$nextTick(() => {
        this.tooltip._fixTitle();
      });
    }
  },
  mounted() {
    this.tooltip = new Tooltip(
      this.$refs['tooltip-toggler'],
      { html: true, placement: 'bottom' }
    );
  },
  methods: {
    buildTipRow(rowTitle, rowData) {
      const sanitized = this.sanitize(rowData);
      return `<div><span>${rowTitle}</span> ${sanitized}</div>`;
    },
    sanitize(text) {
      const pairs = [
        { symbol: '<', replacer: '&lt;' },
        { symbol: '>', replacer: '&rt;' },
      ];
      let result = text;
      _.forEach(pairs, (pair) => {
        result = result.replaceAll(pair.symbol, pair.replacer);
      });
      return result;
    }
  }
};
</script>
<style>
.labor-plan-tooltip .tooltip-inner {
  min-width: 200px !important;
  max-width: 450px !important;
}

.labor-plan-tooltip .tooltip-inner > div {
  text-align: left;
}

.labor-plan-tooltip .tooltip-inner > div > span {
  font-weight: bold;
}
</style>
