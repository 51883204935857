<template>
  <div
    id="confirmation-modal"
    ref="confirmation-modal"
    aria-hidden="true"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  >
    <div class="modal-dialog">
      <div class="modal-content panel-warning">
        <div class="modal-header panel-heading">
          <slot name="header">
            <h4><i class="bi bi-exclamation-triangle" /> Warning!</h4>
          </slot>
        </div>
        <div class="modal-body">
          <slot name="content" />
        </div>
        <div class="modal-footer">
          <button
            aria-label="Close"
            class="btn btn-warning "
            type="submit"
            @click="cancelEditHandler"
          >
            <slot name="cancel-button-text">
              I disagree and Cancel
            </slot>
          </button>
          <button
            :disabled="isSavingInProcess"
            aria-label="Close"
            class="btn btn-success"
            type="submit"
            @click="saveChangesHandler"
          >
            <slot
              :isSavingInProcess="isSavingInProcess"
              name="confirm-button-text"
            >
              {{ isSavingInProcess ? 'Saving...' : 'I agree and Save' }}
            </slot>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Modal } from 'bootstrap';

export default {
  provide: ['confirmationModal'],
  props: [ 'onCancelHandler', 'onConfirmHandler', 'preventHide' ],
  data() {
    return {
      confirmationModal: null,
      isSavingInProcess: false,
    };
  },
  mounted() {
    this.confirmationModal = new Modal(this.$refs['confirmation-modal']);
  },
  methods: {
    async saveChangesHandler() {
      this.isSavingInProcess = true;
      await this.onConfirmHandler();
      if (!this.preventHide) {
        this.confirmationModal.hide();
      }
      this.isSavingInProcess = false;
    },
    async cancelEditHandler() {
      await this.onCancelHandler();
      this.confirmationModal.hide();
      this.isSavingInProcess = false;
    }
  }
};
</script>
